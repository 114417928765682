import React, { ReactElement, useState, useEffect } from 'react'
import Protocol from './components/Protocol'
import TypeSelector from './components/TypeSelector'
import { AccountSchema } from '@components/Schema/AccountSchema'
import { createCaseRequest, getAccountInfoRequest, addPartyRequest, addAgentRequest } from './ajax'
import { USERINFO } from '@redux/actions/basic'
import { connect } from 'react-redux'
import { BASIC_STATE } from '@redux/reducers/basic'
import { awaitWrap, compress, decompress } from '@js/tool'
import { message } from 'antd'
import { History } from 'history'

// 获取用户账号信息
async function getAccountInfo (setLoading: (v: boolean) => void, setAccountInfo: (v: AccountSchema | null) => void): Promise<void> {
  const hide = message.loading('加载中', 120)
  setLoading(true)
  const [e, d] = await awaitWrap(getAccountInfoRequest())
  setLoading(false)
  hide()
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('账号数据未返回')
  } else {
    if (typeof d.license === 'string' && d.license !== '') {
      d.license = decompress(d.license)
    }
    if (typeof d.mobile === 'string' && d.mobile !== '') {
      d.mobile = decompress(d.mobile)
    }
    setAccountInfo(d)
  }
}

// 创建案件
async function createCase (type: string, history: History, userInfo: USERINFO, accountInfo: AccountSchema | null): Promise<void> {
  if (accountInfo === null) {
    return await message.error('账号信息不存在')
  }
  const hide = message.loading('创建案件中', 120)
  const [e, caseid] = await awaitWrap(createCaseRequest())
  if (e !== null) {
    hide()
    return undefined
  }
  if (caseid === null) {
    hide()
    return await message.error('案号未返回')
  }
  const addFn = type === '个人' ? addParty : addAgent
  const [err] = await awaitWrap(addFn(caseid, userInfo, accountInfo))
  hide()
  if (err !== null) {
    return undefined
  }
  history.push(`/case-edit/${caseid}`)
}

// 根据类型和账号信息返回对象
function formatInfoObj (isParty: boolean, caseid: string, userInfo: USERINFO, info: AccountSchema): object {
  const o: {[p: string]: string | []} = {
    caseid,
    mobile: compress(info.mobile),
    fileid: info.fileid,
    fileid1: info.fileid1,
    name: info.name,
    license: compress(info.license),
    license_type: info.license_type,
    accountid: userInfo.id
  }
  if (isParty) {
    o.type = '申请人'
    o.subject_type = '自然人'
    o.tag = JSON.stringify({ subject_type_tag: '国内自然人' })
  } else {
    o.party_type = '申请人'
    o.party = []
  }
  return o
}

// 新建当事人
async function addParty (caseid: string, userInfo: USERINFO, info: AccountSchema): Promise<void> {
  const o = formatInfoObj(true, caseid, userInfo, info)
  const [e] = await awaitWrap(addPartyRequest(o))
  if (e !== null) {
    throw new Error(e.message)
  }
}

// 新建当事人
async function addAgent (caseid: string, userInfo: USERINFO, info: AccountSchema): Promise<void> {
  const o = formatInfoObj(false, caseid, userInfo, info)
  const [e] = await awaitWrap(addAgentRequest(o))
  if (e !== null) {
    throw new Error(e.message)
  }
}

interface Props {
  userInfo: USERINFO
  history: History
}

function Main ({ userInfo, history }: Props): ReactElement {
  const [agree, setAgree] = useState(false)
  const [loading, setLoading] = useState(false)
  const [accountInfo, setAccountInfo] = useState<AccountSchema | null>(null)
  useEffect(() => {
    getAccountInfo(setLoading, setAccountInfo).catch(e => console.error(e))
  }, [])
  if (!agree) {
    return <Protocol setAgree={setAgree} loading={loading} />
  }
  return (
    <TypeSelector onChange={async v => await createCase(v, history, userInfo, accountInfo)} />
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
