import React, { ReactElement, useRef, useState, useEffect } from 'react'
import { Form, Select, Input, Space, Button, message } from 'antd'
import { History } from 'history'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import Claim from '../../components/Claim/Index'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import { getPartyListByAccountId, User } from './tool'
import Upload, { FileType, uploadFile } from '@components/Upload/Index'
import { createDefince, PostData } from './ajax'
import { awaitWrap, scrollToAnchor } from '@js/tool'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

interface Params {
  caseid: string
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  refreshCase: () => void
  refreshOpereate: (k: string) => void
}

interface FormData {
  defenderid: string[]
  defender?: string[]
  statement: string
  file?: FileType[]
}

// 格式化数据
function formatData (d: FormData, c: CaseSchema, type: string, userList: User[], fileid: string): PostData {
  const userMap = new Map()
  userList.forEach(li => {
    userMap.set(li.id, li.name)
  })
  const defender = d.defenderid.map(id => {
    return userMap.get(id)
  })
  return {
    caseid: c.id,
    defender: defender.join(),
    defenderid: d.defenderid.join(),
    statement: d.statement,
    fileid,
    submittal_type: type,
    claimid: c.claim.id
  }
}

// 提交事件
async function submitHandle (d: FormData, c: CaseSchema, type: string, userList: User[], history: History, refreshCase: () => void, refreshOpereate: (v: string) => void): Promise<void> {
  let fileid = ''
  if (d.file instanceof Array) {
    const [e, l] = await awaitWrap(uploadFile(d.file))
    if (e !== null) {
      return undefined
    } else if (l === null) {
      return await message.error('文件id未返回')
    } else {
      fileid = l.map(li => li.id).join()
    }
  }
  const [e] = await awaitWrap(createDefince(formatData(d, c, type, userList, fileid)))
  if (e !== null) {
    return undefined
  }
  history.push(`/case-detail/${c.id}`)
  refreshOpereate('party_dabian')
  refreshCase()
  setTimeout(() => {
    scrollToAnchor('#party_dabian', history)
  }, 500)
}

function cancelToBack (id: string, h: History): void {
  h.push(`/case-detail/${id}`)
  setTimeout(() => {
    scrollToAnchor('#party_dabian', h)
  }, 1000)
}

function Main ({ caseDetail, userInfo, refreshCase, refreshOpereate }: Props): ReactElement {
  const history = useHistory()
  const params = useParams<Params>()
  const formRef = useRef(null)
  const [type, setType] = useState('')
  const [userList, setUserList] = useState<User[]>([])
  useEffect(() => {
    const o: { type: string, list: User[] } = getPartyListByAccountId(caseDetail, userInfo.id)
    setUserList(o.list)
    setType(o.type)
  }, [caseDetail, userInfo])
  return (
    <div>
      <div className={style.header}>答辩</div>
      <Claim data={caseDetail.claim} />
      <div className={style.block}>
        <div className={style['block-title']}>仲裁答辩</div>
        <Form ref={formRef} labelCol={{ flex: '80px' }} onFinish={async d => await submitHandle(d, caseDetail, type, userList, history, refreshCase, refreshOpereate)}>
          <Item label='答辩人' name='defenderid' rules={[{ required: true, message: '答辩人不能为空' }]}>
            <Select placeholder='请选择答辩人' mode='multiple'>
              {userList.map(li => {
                return <Option key={li.id} value={li.id}>{li.name}</Option>
              })}
            </Select>
          </Item>
          <Item label='答辩内容' name='statement' rules={[{ required: true, message: '答辩内容不能为空' }]}>
            <TextArea autoSize={{ minRows: 5 }} placeholder='请输入答辩内容' />
          </Item>
          <Item label='附件材料' name='file'>
            <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
          </Item>
          <div className={style['btn-line']}>
            <Space>
              <Button htmlType='button' onClick={() => cancelToBack(params.caseid, history)}>取消答辩</Button>
              <Button type='primary' htmlType='submit'>确认提交</Button>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
