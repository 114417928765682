import { awaitWrap, decompress } from '@src/assets/js/tool'
import { setToken, setUserInfo } from '@src/redux/actions/basic'
import { Result, Spin } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { loginRequest } from './ajax'
import { History } from 'history'
import qs from 'query-string'
import style from './Index.module.less'
import _ from 'lodash'

async function login (token: string, caseid: string, operate: string, h: History, dispatch: Dispatch, setMessage: (v: string) => void, setLoading: (v: boolean) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(loginRequest(token, caseid))
  if (e !== null) {
    setLoading(false)
    setMessage(e.message)
  }
  if (e === null && d !== null) {
    if (d.userInfo !== null && typeof d.userInfo.mobile === 'string' && d.userInfo.mobile !== '') {
      d.userInfo.mobile = decompress(d.userInfo.mobile)
    }
    dispatch(setUserInfo(d.userInfo))
    dispatch(setToken(d.token))
    setTimeout(() => {
      setLoading(false)
      if (typeof d.caseid === 'string' && d.caseid.length > 0) {
        h.replace(operate !== 'detail' ? `/case-edit/${d.caseid}` : `/case-detail/${d.caseid}`)
      } else {
        h.replace('/create-case')
      }
    }, 100)
  }
}

function Main (): ReactElement {
  const h = useHistory()
  const [params, setParams] = useState<{[k: string]: any}>({ token: '', caseid: '', operate: '' })
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [caseid, setCaseid] = useState('')
  const [message, setMessage] = useState('')
  const [operate, setOperate] = useState('')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const o = qs.parse(location.search)
    if (_.isPlainObject(o)) {
      setLoading(false)
    }
    setParams(o)
  }, [location])
  useEffect(() => {
    if (typeof params.token === 'string') {
      setToken(params.token)
    } else {
      setMessage('token不能为空')
    }
    if (typeof params.caseid === 'string') {
      setCaseid(params.caseid)
    }
    if (typeof params.operate === 'string') {
      setOperate(params.operate)
    }
  }, [params])
  useEffect(() => {
    if (typeof token === 'string' && token.length > 0) {
      login(token, caseid, operate, h, dispatch, setMessage, setLoading).catch(e => console.error(e))
    }
  }, [token])
  return (
    <div>
      {loading && (
        <div className={style.loading}>
          <Spin size='large' />
        </div>
      )}
      {
        !loading && message && (
          <Result
            status='error'
            title='登录失败'
            subTitle={message}
          />
        )
      }
    </div>
  )
}

export default Main
