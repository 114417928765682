import React, { ReactElement, useRef, useState, useEffect, RefObject } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { getObjectURL } from '@js/tool'
import style from './Index.module.less'

interface Props {
  value?: any
  label: string
  disabled?: boolean
  isFront: boolean
  onChange?: (v: any) => void
}

// 改变图片
function changeFile (files: FileList | null, setFile: (v: File) => void, setFileUrl: (v: string) => void, onChange: (v: any) => void): void {
  if (files === null || files.length === 0) {
    return undefined
  }
  const f = files[0]
  onChange(f)
  setFile(f)
  setFileUrl(getObjectURL(f))
}

// 移除图片
function removeHandle (e: React.MouseEvent<HTMLDivElement, MouseEvent>, inputRef: RefObject<HTMLInputElement>, setFile: (v: File | null) => void, setFileUrl: (v: string) => void, onChange: (v: any) => void): void {
  e.preventDefault()
  e.stopPropagation()
  const c = inputRef.current
  if (c !== null) {
    c.value = ''
  }
  onChange(undefined)
  setFile(null)
  setFileUrl('')
}

// 初始化数据
function initData (value: any, setFile: (v: File | null) => void, setFileUrl: (v: string) => void): void {
  if (value !== null && value !== undefined) {
    setFile(value)
    setFileUrl(value instanceof File ? getObjectURL(value) : value.path)
  }
}

function Main ({ label, disabled = false, isFront, value, onChange = () => {} }: Props): ReactElement {
  const inputRef = useRef(null)
  const [fileUrl, setFileUrl] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [delAble, setDelAble] = useState(false)
  const names = classNames([style.block], {
    [style.front]: isFront,
    [style.back]: !isFront,
    [style.disabled]: disabled
  })
  useEffect(() => {
    setDelAble(!disabled && (fileUrl !== '' || file !== null))
  }, [disabled, fileUrl, file])
  useEffect(() => {
    initData(value, setFile, setFileUrl)
  }, [value])
  return (
    <div className={style.container}>
      <label className={names}>
        {disabled ? null : <input className={style['file-input']} ref={inputRef} type='file' onChange={e => changeFile(e.target.files, setFile, setFileUrl, onChange)} />}
        <div className={style.plus}>
          <PlusOutlined />
        </div>
        {fileUrl !== '' ? <img className={style.pic} src={fileUrl} /> : null}
        {delAble ? <div className={style.delBtn} onClick={e => removeHandle(e, inputRef, setFile, setFileUrl, onChange)} /> : null}
      </label>
      {label !== '' && label !== undefined ? <div className={style.title}>{label}</div> : null}
    </div>
  )
}

export default Main
