import React, { ReactElement, useState, useEffect } from 'react'
import {} from 'antd'
import classNames from 'classnames'
import style from './Index.module.less'
import SelectorModal from './components/SelectorModal/Index'

interface Props {
  className?: string
  value?: { id: string, name: string }
  onChange?: (v: { id: string, name: string }) => void
  disabled: boolean
}

function onClick (disabled: boolean, setVisible: (v: boolean) => void): void {
  if (!disabled) {
    setVisible(true)
  }
}

function Main ({ className = '', value, onChange = () => {}, disabled = false }: Props): ReactElement {
  const names = classNames(style.container, { [className]: className !== '' })
  const [arbitratorName, setArbitratorName] = useState<string>('')
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (value !== undefined) {
      setArbitratorName(value.name !== null && value.name !== '' && value.name !== undefined ? value.name : '')
    }
  }, [value])
  return (
    <div className={names}>
      <SelectorModal visible={visible} hide={() => setVisible(false)} value={value} onChange={v => onChange(v)} />
      {disabled
        ? (<span>{arbitratorName === '' ? '未选择' : arbitratorName }</span>)
        : (
          <input type='text' value={arbitratorName} className={classNames('ant-input', style.input, { 'ant-input-disabled': disabled })} placeholder='请选择仲裁员' readOnly onClick={() => onClick(disabled, setVisible)} />
          )}
    </div>
  )
}

export default Main
