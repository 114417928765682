import React, { ReactElement } from 'react'
import style from './TypeSelector.module.less'
import personImg from '@imgs/apply-person.png'
import companyImg from '@imgs/apply-company.png'

interface Props {
  onChange: (v: string) => void
}

function Main ({ onChange }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.block} onClick={() => onChange('个人')}>
        <img className={style.icon} src={personImg} />
        <div className={style['block-title']}>个人申请</div>
        <div className={style['block-message']}>自然人自行申请，请点击进入</div>
      </div>
      <div className={style.block} onClick={() => onChange('代理人')}>
        <img className={style.icon} src={companyImg} />
        <div className={style['block-title']}>代理人申请</div>
        <div className={style['block-message']}>企业、个体工商户等单位代理人或负责人申请，或个人代理人，请点击进入</div>
      </div>
    </div>
  )
}

export default Main
