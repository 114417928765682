import React, { ReactElement, useState, useEffect } from 'react'
import style from './Input.module.less'
import ClearBtn from './ClearBtn'

interface Props {
  value: string
  dispatch: (o: any) => void
  onEnter: () => void
}

// 监听回车键事件
function enterHandle (e: React.KeyboardEvent, onEnter: () => void): void {
  const { keyCode, which, charCode } = e
  if (keyCode === 13 || which === 13 || charCode === 13) {
    onEnter()
  }
}

// 清除输入
function clear (dispatch: (o: any) => void, setClearVisible: (v: boolean) => void): void {
  dispatch({ type: 'password', data: '' })
  dispatch({ type: 'passwordError', data: '' })
  setClearVisible(false)
}

function Main ({ value, dispatch, onEnter }: Props): ReactElement {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (value.length > 0) {
      setVisible(true)
    } else if (value.length === 0 && visible) {
      setVisible(false)
    }
  }, [value])
  return (
    <div className={style.container}>
      <input type='password' autoComplete='off' className={style.input} value={value} placeholder='请输入密码' onChange={e => dispatch({ type: 'password', data: e.target.value })} onKeyPress={e => enterHandle(e, onEnter)} />
      <ClearBtn visible={visible} onClick={() => clear(dispatch, setVisible)} />
    </div>
  )
}

export default Main
