import React, { ReactElement, RefObject, useRef, useEffect, useState } from 'react'
import { Form, FormInstance, Space, Button, TreeSelect, message } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import { SubData } from '../../ajax'
import { getTreeRequest, Tree } from './ajax'
import { awaitWrap } from '@js/tool'

const { Item } = Form

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  cause_action?: string
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const o: FormData = { }
  const a = caseDetail.cause_action
  if (a !== null && a !== undefined && a !== '') {
    o.cause_action = a
  }
  c.setFieldsValue(o)
}

// 初始化案由树
async function initTree (setTreeData: (t: Tree[]) => void, callback: () => void): Promise<void> {
  const hide = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getTreeRequest())
  hide()
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('案由树未返回')
  } else {
    setTreeData(d)
    callback()
  }
}

function Main ({ history, caseDetail, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  const [treeData, setTreeData] = useState<Tree[]>([])
  useEffect(() => {
    initTree(setTreeData, () => { initData(formRef, caseDetail) }).catch(e => console.error(e))
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>案由</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={d => onChange(d)}>
        <Item label='案由' name='cause_action'>
          <TreeSelect
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            className={style.long}
            placeholder='请选择案由'
            showSearch
          />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
