import React, { ReactElement, useState, useEffect } from 'react'
import { AgentPartySchema } from '@components/Schema/AgentSchema'
import style from './Index.module.less'

interface Props {
  data: AgentPartySchema
  idNameMap: {[p: string]: string}
  onClick: (d: AgentPartySchema, index: number) => void
  index: number
}

function Main ({ data, idNameMap, index, onClick }: Props): ReactElement {
  const [name, setName] = useState('')
  useEffect(() => {
    setName(idNameMap[data.partyid])
  }, [data, idNameMap])
  return (
    <button className={style.container} type='button' onClick={() => onClick(data, index)}>
      <span>{name}</span>
    </button>
  )
}

export default Main
