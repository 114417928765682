import React, { ReactElement } from 'react'
import style from './Index.module.less'
import IdCards from '../../../IdCards/Index'
import classNames from 'classnames'
import { PartySchema } from '@src/components/Schema/PartySchema'
import { AgentSchema } from '@src/components/Schema/AgentSchema'
import { filterEmptyText } from '@js/tool'

interface LineProps {
  label: string
  text: string
  index?: number
}

function Line ({ label, text, index }: LineProps): ReactElement {
  return (
    <div className={style.line}>
      {
        index !== undefined
          ? (
          <div className={style.order}>
            <span>{index + 1}</span>
          </div>
            )
          : null
      }
      <span className={style.label}>{label}</span>
      <span className={style.text}>{filterEmptyText(text)}</span>
    </div>
  )
}

interface Props {
  index?: number
  isRespondent?: boolean
  data: PartySchema | AgentSchema
}

function Main ({ index, isRespondent = false, data }: Props): ReactElement {
  const names = classNames(style.container, { [style.respondent]: isRespondent })
  return (
    <div className={names}>
      <div className={style.info}>
        <Line label='姓名' text={data.name} index={index} />
        <Line label='手机号' text={data.mobile} />
        <Line label='证件号码' text={data.license} />
        <Line label='地址' text={data.address} />
      </div>
      <div className={style.cards}>
        <IdCards front={data.file} back={data.file1} />
      </div>
    </div>
  )
}

export default Main
