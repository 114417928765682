import { TablePaginationConfig } from 'antd'
export const initialState: TablePaginationConfig = {
  current: 1,
  pageSize: 5,
  pageSizeOptions: ['5', '10', '20', '50', '100'],
  total: 0,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total: number) => `共 ${total} 位`,
  size: 'small'
}

export function reducer (state: TablePaginationConfig, action: { type: string, data: any }): TablePaginationConfig {
  return {
    ...state,
    [action.type]: action.data
  }
}
