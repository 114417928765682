import React, { ReactElement, useRef, useState, useEffect, useReducer, Dispatch, RefObject } from 'react'
import { Modal, FormInstance, Table, TablePaginationConfig, Space, Button } from 'antd'
import { awaitWrap } from '@js/tool'
import { columns } from '../../config'
import { initialState, reducer } from './reducer'
import { ArbitratorSchema } from '@src/components/Schema/ArbitratorSchema'
import { TableRowSelection } from 'antd/lib/table/interface'
import { GetComponentProps } from 'rc-table/lib/interface'
import SearchForm from './components/SearchForm/Index'
import { getArbitrators } from './ajax'

interface Props {
  visible: boolean
  value?: { id: string, name: string } | undefined
  onChange?: (v: { id: string, name: string }) => void
  hide: () => void
}

// 获取仲裁员列表
async function initArbitrators (
  p: TablePaginationConfig,
  data: string,
  setLoading: (v: boolean) => void,
  setDatasource: (v: ArbitratorSchema[]) => void,
  dispatch: Dispatch<any>
): Promise<void> {
  setLoading(true)
  const current = p.current === undefined ? 1 : p.current
  const pageSize = p.pageSize === undefined ? 5 : p.pageSize
  const [e, d] = await awaitWrap(getArbitrators(current, pageSize, data))
  setLoading(false)
  if (e === null && d !== null) {
    d.list.forEach((li, i) => {
      li.no = i + ((p.current as number) - 1) * (p.pageSize as number) + 1
    })
    setDatasource(d.list)
    dispatch({ type: 'total', data: d.total })
  }
}

// 改变表单
function tableChange (p: TablePaginationConfig, dispatch: Dispatch<any>, formRef: RefObject<FormInstance>): void {
  dispatch({ type: 'current', data: p.current })
  dispatch({ type: 'pageSize', data: p.pageSize })
  formRef.current?.submit()
}

// 确定回调
function onOkHandle (datasource: ArbitratorSchema[], selectedRowKeys: string[], onChange: (v: { id: string, name: string }) => void, hide: () => void): void {
  if (selectedRowKeys.length > 0) {
    const id = selectedRowKeys[0]
    for (let i = 0; i < datasource.length; i++) {
      const o = datasource[i]
      if (o.id === id) {
        onChange({
          id: o.id,
          name: o.name
        })
        break
      }
    }
  }
  hide()
}

function Main ({ visible, hide, value, onChange = () => {} }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  const [loading, setLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [datasource, setDatasource] = useState<ArbitratorSchema[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const rowSelection: TableRowSelection<ArbitratorSchema> = {
    selectedRowKeys,
    type: 'radio',
    onChange: (d) => setSelectedRowKeys(d as string[])
  }
  const onRow: GetComponentProps<ArbitratorSchema> = (record, index) => {
    return {
      onClick: () => {
        if (index !== undefined) {
          setSelectedRowKeys([datasource[index]?.id])
        }
      }
    }
  }
  useEffect(() => {
    if (visible) {
      formRef.current?.submit()
    } else {
      dispatch({ type: 'current', data: 1 })
      formRef.current?.resetFields()
    }
  }, [visible])
  useEffect(() => {
    if (value !== undefined) {
      setSelectedRowKeys([value.id])
    }
  }, [value])
  return (
    <Modal title="选择仲裁员" visible={visible} width='1280px' okText='确定' cancelText='取消' maskClosable={false} onCancel={() => { setSelectedRowKeys([]); hide() }} footer={(
      <Space>
        <Button type='primary' ghost onClick={() => { onChange({ id: '', name: '委托主任指定' }); hide() }}>委托主任指定</Button>
        <Button onClick={() => { setSelectedRowKeys([]); hide() }}>取消</Button>
        <Button type='primary' onClick={() => onOkHandle(datasource, selectedRowKeys, onChange, hide)}>确定</Button>
      </Space>
    )}>
      <SearchForm onSearchBtnClick={() => dispatch({ type: 'current', data: 1 })} onChange={async d => await initArbitrators(state, d, setLoading, setDatasource, dispatch)} ref={formRef} selectedName={value !== undefined ? value.name : ''} />
      <Table rowKey='id' columns={columns} pagination={state} loading={loading} dataSource={datasource} onChange={(pagination) => tableChange(pagination, dispatch, formRef)} rowSelection={rowSelection} onRow={onRow} />
    </Modal>
  )
}

export default Main
