import React, { ReactElement } from 'react'
import Header from '../Header/Index'
import icon from '@imgs/claim-icon.png'
import style from './Index.module.less'
import { ClaimItemSchema, ClaimSchema } from '@src/components/Schema/ClaimSchema'
import { filterEmptyText, formatDate, formatMoney, isEmptyString } from '@js/tool'
import classNames from 'classnames'

interface Props {
  data: ClaimSchema
  nopad?: boolean
  anti?: boolean
  time?: boolean
}

function Item ({ data, index }: { data: ClaimItemSchema, index: number }): ReactElement {
  return (
    <div className={style.item}>
      <div className={style.order}>
        <span>{index + 1}</span>
      </div>
      <div className={style.content}>{data.type === '标的收费' ? filterEmptyText(data.desc) : data.type}</div>
      <div className={style.number}>{formatMoney(data.dispute_amount)}元</div>
    </div>
  )
}

function Main ({ data, nopad = false, anti = false, time = false }: Props): ReactElement {
  return (
    <div className={classNames(style.container, { [style.nopad]: nopad })}>
      <Header icon={icon} title={`${anti ? '反' : ''}仲裁请求`} subTitle={time ? formatDate(data.create_datetime) : ''} />
      {
        data.items.map((li, i) => {
          return <Item key={i} data={li} index={i} />
        })
      }
      {
        !isEmptyString(data.statement)
          ? (
          <div className={style.message}>
            <span className={style['message-title']}>事实与理由</span>
            <span>{data.statement}</span>
          </div>
            )
          : null
      }
    </div>
  )
}

export default Main
