import { $freePost } from '@js/request'
import { USERINFO } from '@src/redux/actions/basic'

export async function loginRequest (token: string, caseid: string): Promise<{ userInfo: USERINFO, token: string, caseid?: string }> {
  const o: { [k: string]: any } = { token }
  if (typeof caseid === 'string' && caseid.length > 0) {
    o.caseid = caseid
  }
  const res = await $freePost('/api/hs/token_login', o)
  return res.data
}
