import React, { ReactElement, useState, useEffect } from 'react'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import { History } from 'history'
import { connect } from 'react-redux'
import { CaretRightOutlined } from '@ant-design/icons'
import { formatMoney } from '@js/tool'
import style from './Index.module.less'
import icon from '@imgs/claim-icon.png'
import { ClaimItemSchema } from '@src/components/Schema/ClaimSchema'

interface LineProps {
  data: ClaimItemSchema
  userInfo: USERINFO
  onClick: (v: string | undefined) => void
}

function Line ({ data, userInfo, onClick }: LineProps): ReactElement {
  return (
    <div className={style['block-line']} onClick={() => onClick(data.id)}>
      <div className={style['block-line-left']}>
        <span>{data.type === '标的收费' ? data.desc : data.type}</span>
      </div>
      <div className={style['block-line-right']}>
        <span className={style.fee}>{formatMoney(data.dispute_amount)}元</span>
        <CaretRightOutlined />
      </div>
    </div>
  )
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  history: History
}

function Main ({ caseDetail, userInfo, history }: Props): ReactElement {
  const [list, setList] = useState<ClaimItemSchema[]>([])
  useEffect(() => {
    setList(caseDetail.claim.items)
  }, [caseDetail])
  return (
    <div>
      <div className={style.header}>
        <img src={icon} alt="" />
        <span>仲裁请求（请分项录入仲裁请求）</span>
      </div>
      <div className={style.block}>
        {
          list.map((li, i) => {
            return (
              <Line key={i} data={li} userInfo={userInfo} onClick={id => history.push(`/case-edit/${caseDetail.id}/claim/${id as string}`)} />
            )
          })
        }
        <div className={style['btn-line']}>
          <span>请求项</span>
          <div className={style['btn-box']}>
            <button className={style.btn} onClick={() => history.push(`/case-edit/${caseDetail.id}/claim`)}>添加请求项</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
