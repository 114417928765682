import React, { useReducer } from 'react'
import style from './Index.module.less'
import bgImg from '@imgs/logoBg.png'
import logoImg from '@imgs/logo.png'
import LoginForm from './components/LoginForm'
import { reducer, initialState } from './reducer'
import { companyName, appName } from '@js/config'
import { RouteComponentProps } from 'react-router-dom'
import { Space } from 'antd'

function Main ({ history }: RouteComponentProps): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <div className={style.container}>
      <div className={style['body-right']}>
        <img src={bgImg} />
      </div>
      <div className={style.content}>
        <div className={style['logo-line']}>
          <a href='http://service.xmac.org.cn/' className={style['logo-line-inner']}>
            <img className={style['logo-img']} src={logoImg} />
            <span>{companyName}</span>
            <span className={style['logo-point']} />
            <span>{appName}</span>
          </a>
        </div>
        <div className={style['form-container']}>
          <LoginForm state={state} dispatch={dispatch} history={history} />
        </div>
      </div>
      <div className={style.foot}>
        <Space>
          <span>{companyName}</span>
          <span>©2020 service.xmac.org.cn</span>
          <span>闽ICP备12022941号-1</span>
          <span>技术支持：之初科技</span>
        </Space>
      </div>
    </div>
  )
}

export default Main
