import { AgentPartySchema, AgentSchema } from '@src/components/Schema/AgentSchema'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { PartySchema } from '@src/components/Schema/PartySchema'

export interface User {
  id: string
  name: string
}

function getMap (list: Array<PartySchema | AgentSchema>, key: string = 'accountid'): Map<string, PartySchema | AgentSchema> {
  const r = new Map()
  list.forEach(li => {
    r.set(li[key], li)
  })
  return r
}

// 根据当前操作者账号id获取当事人列表
export function getPartyListByAccountId (d: CaseSchema, id: string): { type: string, list: User[] } {
  let type = ''
  const list = []
  const aMap = getMap(d.applicants)
  const aIdMap = getMap(d.applicants, 'id')
  const rMap = getMap(d.respondents)
  const rIdMap = getMap(d.respondents, 'id')
  const aAgentMap = getMap(d.applicant_agents)
  const rAgentMap = getMap(d.respondents_agents)
  if (aMap.has(id)) {
    type = '申请人'
    const o = aMap.get(id)
    if (o?.id !== undefined) {
      list.push({ id: o.id, name: o.name })
    }
  } else if (rMap.has(id)) {
    type = '被申请人'
    const o = rMap.get(id)
    if (o?.id !== undefined) {
      list.push({ id: o.id, name: o.name })
    }
  } else if (aAgentMap.has(id)) {
    type = '申请人代理人'
    const o = aAgentMap.get(id)
    if (o !== undefined) {
      o.party.forEach((li: AgentPartySchema) => {
        const v = aIdMap.get(li.partyid)
        if (v !== undefined) {
          list.push({ id: v.id, name: v.name })
        }
      })
    }
  } else if (rAgentMap.has(id)) {
    type = '被申请人代理人'
    const o = rAgentMap.get(id)
    if (o !== undefined) {
      o.party.forEach((li: AgentPartySchema) => {
        const v = rIdMap.get(li.partyid)
        if (v !== undefined) {
          list.push({ id: v.id, name: v.name })
        }
      })
    }
  }
  return {
    type,
    list
  }
}
