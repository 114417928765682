import React, { ReactElement, RefObject, useRef, useEffect } from 'react'
import { Form, FormInstance, Input, Space, Button, InputNumber, Modal } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import { SubData } from '../../ajax'
import { ClaimItemSchema } from '@src/components/Schema/ClaimSchema'

const { Item } = Form
const { TextArea } = Input
const { confirm } = Modal

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  desc?: string
  dispute_amount?: number
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema, id: string | undefined): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const l: ClaimItemSchema[] = caseDetail.claim.items
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id) {
      c.setFieldsValue(l[i])
      break
    }
  }
}

// 提交
function submitHandle (d: FormData, caseDetail: CaseSchema, id: string | undefined, onChange: (d: SubData) => void): void {
  const o: SubData = { claim: caseDetail.claim }
  const l: ClaimItemSchema[] = o.claim.items
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id) {
      l[i] = { ...l[i], ...d }
      break
    }
  }
  o.claim.items.forEach((li: ClaimItemSchema) => delete li.id)
  onChange(o)
}

// 删除
function delHandle (id: string, caseDetail: CaseSchema, onChange: (d: SubData) => void): void {
  const o: SubData = { claim: caseDetail.claim }
  o.claim.items = o.claim.items.filter((li: ClaimItemSchema) => li.id !== id)
  o.claim.items.forEach((li: ClaimItemSchema) => delete li.id)
  onChange(o)
}

// 删除确认
function confirmDel (caseDetail: CaseSchema, id: string | undefined, onChange: (d: SubData) => void): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确定要删除当前请求吗？',
    onOk () {
      if (id !== undefined) {
        delHandle(id, caseDetail, onChange)
      }
    }
  })
}

function Main ({ history, caseDetail, match, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  useEffect(() => {
    initData(formRef, caseDetail, match.params.id)
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>仲裁请求</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={d => submitHandle(d, caseDetail, match.params.id, onChange)}>
        <Item label='请求内容' name='desc' rules={[{ required: true, message: '请求内容不能为空' }]}>
          <TextArea className={style.long} autoSize={{ minRows: 3 }} placeholder='请输入' />
        </Item>
        <Item label='争议金额' name='dispute_amount' rules={[{ required: true, message: '争议金额不能为空' }]}>
          <InputNumber
            className={style.long}
            placeholder='请输入'
            maxLength={16}
            min={0}
          />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button onClick={() => confirmDel(caseDetail, match.params.id, onChange)}>删除</Button>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
