import React, { ReactElement, RefObject } from 'react'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { FormInstance, Row, Col, InputNumber, Form, Input, Button, message } from 'antd'
import { MinusOutlined } from '@ant-design/icons'
import { splitItem } from '@js/tool'
import style from './Index.module.less'

const { Item } = Form
const { TextArea } = Input

interface Props {
  field: FormListFieldData
  index: number
  len: number
  remove: (index: number | number[]) => void
  formRef: RefObject<FormInstance>
}

// 分项方法
async function autoSplit (formRef: RefObject<FormInstance>): Promise<void> {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const items = c.getFieldValue(['items'])
  const desc = items[0].desc
  if (desc === undefined || desc === '' || desc === null) {
    return await message.warning('请求内容不能为空')
  }
  const list = splitItem(desc)
  c.setFieldsValue({
    items: list.map(li => {
      return {
        desc: li,
        dispute_amount: 0
      }
    })
  })
}

interface DelBtnProps {
  field: FormListFieldData
  len: number
  remove: (v: number) => void
}

// 删除按钮
function DelBtn ({ field, len, remove }: DelBtnProps): ReactElement {
  if (len === 1) {
    return <span />
  }
  return (
    <Col span={2} className={style['delete-btn']}>
      <Button shape='circle' size='small' icon={<MinusOutlined />} onClick={() => remove(field.name)} />
    </Col>
  )
}

function Main ({ field, index, len, formRef, remove }: Props): ReactElement {
  return (
    <Row key={field.name}>
      <Col flex='40px'><span className={style['order-num']}>{index + 1}</span></Col>
      <Col span={14}>
        <Item label='请求内容' name={[field.name, 'desc']} rules={[{ required: true, message: '请求内容不能为空' }]}>
          <TextArea autoSize={{ minRows: 3 }} placeholder='请输入' />
        </Item>
        <Item label='争议金额' name={[field.name, 'dispute_amount']} rules={[{ required: true, message: '争议金额不能为空' }]}>
          <InputNumber
            className={style['number-input']}
            placeholder='请输入'
            maxLength={16}
            min={0}
          />
        </Item>
      </Col>
      <DelBtn len={len} field={field} remove={d => remove(d)} />
      {len === 1 ? <Button type='link' onClick={async () => await autoSplit(formRef)}>智能分项</Button> : null}
    </Row>
  )
}

export default Main
