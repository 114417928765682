import React, { ReactElement, RefObject, useRef, useEffect } from 'react'
import { Form, FormInstance, Input, Space, Button, message } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import Upload, { FileType, uploadFile } from '@components/Upload/Index'
import { SubData } from '../../ajax'
import { awaitWrap } from '@js/tool'

const { Item } = Form
const { TextArea } = Input

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  basis?: string
  basis_file?: FileType[]
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const o: FormData = { basis: caseDetail.basis }
  if (caseDetail.basis_file instanceof Array) {
    o.basis_file = caseDetail.basis_file.map(li => {
      li.name = li.original_filename
      return li
    })
  }
  c.setFieldsValue(o)
}

// 提交
async function submitHandle (d: FormData, onchange: (d: SubData) => void): Promise<void> {
  const o: SubData = { basis: d.basis }
  if (d.basis_file !== undefined) {
    const [e, files] = await awaitWrap(uploadFile(d.basis_file))
    if (e !== null) {
      return undefined
    } else if (files === null) {
      return await message.error('文件未返回')
    }
    o.basis_fileid = files.map(li => li.id).join()
  }
  onchange(o)
}

function Main ({ history, caseDetail, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  useEffect(() => {
    initData(formRef, caseDetail)
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>仲裁依据</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, onChange)}>
        <Item label='仲裁依据' name='basis' rules={[{ required: true, message: '仲裁依据不能为空' }]}>
          <TextArea className={style.long} autoSize={{ minRows: 5 }} placeholder='请输入仲裁依据' />
        </Item>
        <Item name='basis_file' label='附件材料'>
          <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
