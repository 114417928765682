import React, { ReactElement, useState, useEffect } from 'react'
import _ from 'lodash'
import { AgentPartySchema } from '@components/Schema/AgentSchema'
import style from './Index.module.less'
import classNames from 'classnames'
import PartyTag from './components/PartyTag/Index'
import PartyModal from './components/PartyModal/Index'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import Dictionary from '@src/assets/js/dictionary'
import { PartySchema } from '@src/components/Schema/PartySchema'
import { message } from 'antd'

interface Props {
  type: string
  value?: AgentPartySchema[]
  onChange?: (v: AgentPartySchema[]) => void
  caseDetail: CaseSchema
  dictionary: Dictionary
  id?: string
}

// 添加代理对象
function addParty (
  setVisible: (v: boolean) => void,
  setCurrent: (v: AgentPartySchema | null) => void
): void {
  setVisible(true)
  setCurrent(null)
}

// 添加代理对象
function editParty (
  setVisible: (v: boolean) => void,
  setCurrent: (v: AgentPartySchema | null) => void,
  setIndex: (v: number) => void,
  d: AgentPartySchema,
  index: number
): void {
  setVisible(true)
  setCurrent(d)
  setIndex(index)
}

// 隐藏弹框
function hide (
  setVisible: (v: boolean) => void,
  setCurrent: (v: AgentPartySchema | null) => void,
  setIndex: (v: number) => void
): void {
  setVisible(false)
  setCurrent(null)
  setIndex(-1)
}

// 更新代理对象列表
async function updatePartyList (list: AgentPartySchema[], d: AgentPartySchema, i: number, onChange: (v: AgentPartySchema[]) => void, setVisible: (v: boolean) => void, setCurrent: (v: AgentPartySchema | null) => void, setIndex: (v: number) => void): Promise<void> {
  const l = _.cloneDeep(list)
  const ids: string[] = l.map(li => li.partyid)
  if (i !== -1) {
    ids.splice(i, 1)
  }
  if (ids.includes(d.partyid)) {
    return await message.warning('该代理对象已存在，请选择其他代理对象')
  }
  if (i === -1) {
    l.push(d)
  } else {
    l.splice(i, 1, d)
  }
  onChange(l)
  setVisible(false)
  setCurrent(null)
}

// 删除代理对象
function removePartyFromList (list: AgentPartySchema[], i: number, onChange: (v: AgentPartySchema[]) => void, setVisible: (v: boolean) => void, setCurrent: (v: AgentPartySchema | null) => void, setIndex: (v: number) => void): void {
  const l = _.cloneDeep(list)
  l.splice(i, 1)
  onChange(l)
  setVisible(false)
  setCurrent(null)
  setIndex(-1)
}

function Main ({ value = [], type, caseDetail, dictionary, id, onChange = () => {} }: Props): ReactElement {
  const names = classNames(style.container, { [style.respondent]: false })
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState<AgentPartySchema | null>(null)
  const [index, setIndex] = useState<number>(-1)
  const [parties, setParties] = useState<PartySchema[]>([])
  const [idNameMap, setIdNameMap] = useState<{[p: string]: string}>({})
  useEffect(() => {
    setParties(type === 'applicant' ? caseDetail.applicants : caseDetail.respondents)
  }, [caseDetail, type])
  useEffect(() => {
    const o: {[p: string]: string} = {}
    parties.forEach(p => {
      if (p.id !== undefined) {
        o[p.id] = p.name
      }
    })
    setIdNameMap(o)
  }, [parties])
  return (
    <div className={names} id={id}>
      <PartyModal visible={visible} data={current} index={index} caseDetail={caseDetail} parties={parties} dictionary={dictionary} hide={() => hide(setVisible, setCurrent, setIndex)} onChange={async (d, i) => await updatePartyList(value, d, i, onChange, setVisible, setCurrent, setIndex)} removeHandle={(i) => removePartyFromList(value, i, onChange, setVisible, setCurrent, setIndex)} />
      {value?.map((li, i) => {
        return (
          <PartyTag key={li.partyid} data={li} index={i} idNameMap={idNameMap} onClick={(d, i) => editParty(setVisible, setCurrent, setIndex, d, i)} />
        )
      })}
      <button className={style.btn} type='button' onClick={() => addParty(setVisible, setCurrent)}>+ 添加</button>
    </div>
  )
}

export default Main
