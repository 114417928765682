import { ReactElement } from 'react'
import { Result } from 'antd'

function Main (): ReactElement {
  return (
    <div>
       <Result
        status="404"
        title="页面不存在"
        subTitle="请联系管理员"
      />
    </div>
  )
}

export default Main
