import React, { ReactElement, RefObject, useRef, useEffect } from 'react'
import { Form, FormInstance, Space, Button } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import { SubData } from '../../ajax'
import ClaimItem from './components/ClaimItem/Index'
import AddBtnLine from './components/AddBtnLine/Index'
import { ClaimItemSchema } from '@src/components/Schema/ClaimSchema'
import { scrollToFirstError } from '@js/tool'

const { List } = Form

const initialValues = {
  items: [
    { desc: '', dispute_amount: 0 }
  ]
}

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  items?: Array<{ desc: string, dispute_amount: number }>
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const o: FormData = { }
  c.setFieldsValue(o)
}

// 提交
async function submitHandle (d: FormData, caseDetail: CaseSchema, onchange: (d: SubData) => void): Promise<void> {
  const o: SubData = { claim: caseDetail.claim }
  o.claim.items.forEach((li: ClaimItemSchema) => {
    delete li.id
  })
  o.claim.items = o.claim.items.concat(d.items)
  onchange(o)
}

function Main ({ history, caseDetail, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  useEffect(() => {
    initData(formRef, caseDetail)
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>仲裁请求（请分项录入请求，如粘贴一段内容点击智能分项，系统自动帮您分项）</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} initialValues={initialValues} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, caseDetail, onChange)} scrollToFirstError={scrollToFirstError}>
        <List name='items'>
          {(fields, { add, remove }) => (
            <div>
              {
                fields.map((field, i) => <ClaimItem key={field.name} field={field} index={i} len={fields.length} remove={remove} formRef={formRef} />)
              }
              <AddBtnLine onClick={() => add()} />
            </div>
          )}
        </List>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
