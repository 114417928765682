import React, { } from 'react'
import style from './Index.module.less'
import bgImg from '@imgs/logoBg.png'
import logoImg from '@imgs/logo.png'
// import { reducer, initialState } from './reducer'
import { RouteComponentProps } from 'react-router-dom'
import { companyName, appName, companyUrl } from '@js/config'
import Form from './components/Form'

function Main ({ history }: RouteComponentProps): React.ReactElement {
  return (
    <div className={style.container}>
      <div className={style['body-right']}>
        <img src={bgImg} />
      </div>
      <div className={style.content}>
        <div className={style['logo-line']}>
          <a href='http://service.xmac.org.cn/' className={style['logo-line-inner']}>
            <img className={style['logo-img']} src={logoImg} />
            <span>{companyName}</span>
            <span className={style['logo-point']} />
            <span>{appName}</span>
          </a>
        </div>
        <div className={style['form-container']}>
          <Form history={history} />
        </div>
      </div>
      <div className={style.foot}>
        <span>{companyName}  {companyUrl}</span>
      </div>
    </div>
  )
}

export default Main
