import { $post } from '@js/request'

export interface PostData {
  caseid: string
  defender: string
  defenderid: string
  statement: string
  fileid?: string
  submittal_type: string
  claimid: string
}

// 保存答辩
export async function createDefince (data: PostData): Promise<void> {
  const res = await $post('/api/hs/defence', data)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
