import React, { useState, useEffect } from 'react'
import { Popover, Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import FreeScrollBar from 'react-free-scrollbar'
import classnames from 'classnames'
import telZone, { Zone } from '@js/telZone'
import style from './Phone.module.less'
import ClearBtn from './ClearBtn'
const { Search } = Input

interface Props {
  zoneNum: string
  mobile: string
  dispatch: (o: any) => void
  onEnter: () => void
}

// 监听回车键事件
function enterHandle (e: React.KeyboardEvent, onEnter: () => void): void {
  const { keyCode, which, charCode } = e
  if (keyCode === 13 || which === 13 || charCode === 13) {
    onEnter()
  }
}

interface ZoneProps {
  value: string
  setZoneNum: (v: string) => void
  setVisible: (v: boolean) => void
}

// 根据搜索条件过滤空间列表
function filterListBySearch (zone: string, setList: (v: Zone[]) => void): void {
  let list = telZone
  if (zone !== '') {
    list = list.filter(li => {
      const en = li.en.toLocaleLowerCase()
      const s = zone.toLocaleLowerCase()
      const isTel = li.tel.includes(s)
      const isCn = li.name.includes(s)
      const isEn = en.includes(s)
      return isTel || isCn || isEn
    })
  }
  setList(list)
}

function ZoneList ({ value, setZoneNum, setVisible }: ZoneProps): React.ReactElement {
  const [zone, setZone] = useState<string>('')
  const [list, setList] = useState<Zone[]>(telZone)
  useEffect(() => {
    filterListBySearch(zone, setList)
  }, [zone])
  return (
    <div className={style['zone-tel-block']}>
      <Search
        placeholder='请输入区号、中文名或者英文名'
        onChange={e => setZone(e.target.value)}
        value={zone}
        allowClear
        className={style['zone-tel-search']}
      />
      <ul className={style['zone-list']}>
        <FreeScrollBar className='mock'>
          {list.map((li, i) => {
            const names = classnames(style['zone-li'], { [style.active]: li.tel === value })
            return (
              <li className={names} key={i} onClick={() => { setZoneNum(li.tel); setVisible(false); setZone('') }}>
                <span className={style['zone-tel']}>+{li.tel}</span>
                <span className={style['zone-cn']}>{li.name}</span>
                <span className={style['zone-en']}>({li.en})</span>
              </li>
            )
          })}
        </FreeScrollBar>
      </ul>
    </div>
  )
}

// 清除输入
function clearPhone (dispatch: (o: any) => void, setClearVisible: (v: boolean) => void): void {
  dispatch({ type: 'mobile', data: '' })
  dispatch({ type: 'mobileError', data: '' })
  setClearVisible(false)
}

function Main ({ zoneNum, mobile, dispatch, onEnter }: Props): React.ReactElement {
  const [visible, setVisible] = useState(false)
  const [clearVisible, setClearVisible] = useState(false)
  useEffect(() => {
    if (mobile.length > 0) {
      setClearVisible(true)
    } else if (mobile.length === 0 && clearVisible) {
      setClearVisible(false)
    }
  }, [mobile])
  return (
    <div className={style.container}>
      <div className={style.zone}>
        <input className={style['zone-input']} type='text' maxLength={5} value={`+${zoneNum}`} autoComplete='off' onChange={v => dispatch({ type: 'zoneNum', data: v.target.value.slice(1) })} onKeyPress={e => enterHandle(e, onEnter)} />
        <Popover
          content={<ZoneList value={zoneNum} setZoneNum={v => dispatch({ type: 'zoneNum', data: v })} setVisible={setVisible} />} trigger='click' visible={visible}
          onVisibleChange={v => setVisible(v)}
        >
          <div className={style['zone-block-arrow']}>
            <DownOutlined />
          </div>
      </Popover>
      </div>
      <input type="text" className={style['phone-input']} maxLength={11} value={mobile} autoComplete='off' onChange={e => dispatch({ type: 'mobile', data: e.target.value })} placeholder='请输入手机号码' onKeyPress={e => enterHandle(e, onEnter)} />
      <ClearBtn visible={clearVisible} onClick={() => clearPhone(dispatch, setClearVisible)} />
    </div>
  )
}

export default Main
