import React, { ReactElement } from 'react'
import style from './Index.module.less'
import applicantIcon from '@imgs/applicant-icon.png'
import respondentIcon from '@imgs/respondent-icon.png'
import Header from '../Header/Index'
import Item from './components/Item/Index'
import { PartySchema } from '@src/components/Schema/PartySchema'
import { AgentSchema } from '@src/components/Schema/AgentSchema'

interface Props {
  isRespondent?: boolean
  title: string
  data: PartySchema[] | AgentSchema[]
}

function Main ({ isRespondent = false, title, data = [] }: Props): ReactElement {
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  return (
    <div className={style.container}>
      <Header icon={isRespondent ? respondentIcon : applicantIcon} title={title} />
      {
        data.map((li: PartySchema | AgentSchema, i: number) => {
          return <Item key={li.id} isRespondent={isRespondent} data={li} index={i} />
        })
      }
    </div>
  )
}

export default Main
