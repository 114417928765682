// import React from 'react'
// import { Redirect } from 'react-router-dom'
import Login from '@src/pages/Login/Index'
import Verification from '@src/pages/Verification/Index'
import noFound from '@src/pages/404/Index'
import CreateCase from '@src/pages/CreateCase/Index'
import CaseEdit from '@src/pages/CaseEdit/Index'
import CaseDetail from '@src/pages/CaseDetail/Index'
import Preview from '@src/pages/CaseDetail/Preview'
import CreateSuccess from '@src/pages/CreateSuccess/Index'
import CaseUneditable from '@src/pages/CaseUneditable/Index'
import { routerConfigs } from './registerRouter'
import TokenLogin from '@src/pages/TokenLogin/Index'

interface Page {
  path: string
  component: React.FC<any>
  auth: boolean
  checkeAuth?: boolean
  exact: boolean
}

export const RouterConfig: Page[] = [
  {
    path: '/login',
    component: Login,
    auth: false,
    exact: true
  },
  {
    path: '/verification',
    component: Verification,
    auth: true,
    exact: true
  },
  {
    path: '/404',
    component: noFound,
    auth: true,
    exact: true
  },
  {
    path: '/create-case',
    component: CreateCase,
    auth: true,
    exact: true
  },
  {
    path: '/case-edit/:caseid',
    component: CaseEdit,
    auth: true,
    exact: true
  },
  {
    path: '/case-edit/:caseid/*',
    component: CaseEdit,
    auth: true,
    exact: false
  },
  {
    path: '/case-detail/:caseid',
    component: CaseDetail,
    auth: true,
    exact: false
  },
  {
    path: '/case-preview/:caseid',
    component: Preview,
    auth: true,
    exact: false
  },
  {
    path: '/create-success',
    component: CreateSuccess,
    auth: true,
    exact: true
  },
  {
    path: '/case-uneditable',
    component: CaseUneditable,
    auth: true,
    exact: true
  },
  {
    path: '/',
    component: TokenLogin,
    auth: false,
    exact: true
  },
  ...routerConfigs
]
