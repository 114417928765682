import React from 'react'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
import { OperateItem } from './ajax'

interface InitProps {
  courtItems: TribunalPartySchema[]
  setCourtItems: (v: TribunalPartySchema[]) => void
  usedOperates: OperateItem[]
}

export const Context = React.createContext<InitProps>({
  courtItems: [],
  setCourtItems: (v: TribunalPartySchema[]) => {},
  usedOperates: []
})
