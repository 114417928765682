import React, { ReactElement, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Form, FormInstance, Col, Row, Input, Select, Space, Button, message } from 'antd'
import _ from 'lodash'
import AreaSelector, { AreaOption } from '../AreaSelector/Index'
import style from './Index.module.less'
import { getArea } from './ajax'
import { awaitWrap, isEmptyString } from '@js/tool'
import Dictionary, { DictionaryItem } from '@js/dictionary'

const { Item } = Form
const { Option } = Select

interface Props {
  onChange: (v: string) => void
  onSearchBtnClick: () => void
  selectedName: string
}

// 初始化地域可选列表
async function initArea (setAreas: (v: AreaOption) => void): Promise<void> {
  const [e, d] = await awaitWrap(getArea())
  if (e === null && d !== null) {
    setAreas(d)
  }
}

// 格式化搜索条件
function formatParams (d: FormData): string {
  const condition: any[] = []
  if (d.area.length > 0) {
    condition.push({ k: 'area', o: 'in', v: d.area })
  }
  if (!isEmptyString(d.name)) {
    condition.push({ k: 'name', o: 'like', v: `%${d.name}%` })
  }
  if (d.gender !== '全部') {
    condition.push({ k: 'gender', o: '=', v: d.gender })
  }
  if (d.occupation_out !== '全部') {
    condition.push({ k: 'occupation_out', o: '=', v: d.occupation_out })
  }
  if (d.professional !== '全部') {
    condition.push({ k: 'professional', o: '=', v: d.professional })
  }
  return JSON.stringify(condition)
}

interface FormData {
  name: string
  gender: string
  occupation_out: string
  professional: string
  area: string[]
}

function Main ({ onChange, onSearchBtnClick, selectedName = '' }: Props, ref: React.Ref<any>): ReactElement {
  const formRef = useRef<FormInstance>(null)
  const [areas, setAreas] = useState<AreaOption>({ 本地: [], 外籍: [], 外地: [] })
  const [initialValues, setInitialValues] = useState<FormData>({
    area: [],
    gender: '全部',
    occupation_out: '全部',
    professional: '全部',
    name: ''
  })
  const [occupation, setOccupation] = useState<DictionaryItem[]>([])
  const [profession, setProfession] = useState<DictionaryItem[]>([])
  const [ready, setReady] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  useImperativeHandle(ref, () => ({
    submit: () => {
      setSubmiting(true)
    },
    resetFields: () => {
      formRef.current?.resetFields()
    }
  }))
  useEffect(() => {
    const dictionary = new Dictionary()
    const hide = message.loading('加载中', 120)
    Promise.all([initArea(setAreas), dictionary.init('仲裁员职业分类（对外）,专业')])
      .then(() => {
        hide()
        setReady(true)
        setOccupation(dictionary.getList('仲裁员职业分类（对外）'))
        setProfession(dictionary.getList('专业'))
      })
      .catch(e => {
        hide()
        console.error(e)
      })
  }, [])
  useEffect(() => {
    const area = _.concat([], areas.外地, areas.外籍, areas.本地)
    setInitialValues({
      ...initialValues,
      area
    })
    const c = formRef.current
    if (c !== null) {
      c.setFieldsValue({ area })
    }
  }, [areas])
  useEffect(() => {
    if (ready && submiting) {
      formRef.current?.submit()
    }
  }, [submiting, ready])
  return (
    <Form name='arbitrator' ref={formRef} initialValues={initialValues} onFinish={d => {
      onChange(formatParams(d))
      setSubmiting(false)
    }}>
      <Row className={style['input-line']}>
        <Col span={8}>
          <Row>
            <Col flex='80px' className={style.label}>姓名：</Col>
            <Col flex='auto'>
              <Item name='name' noStyle>
                <Input placeholder='请输入' />
              </Item>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col flex='80px' className={style.label}>性别：</Col>
            <Col flex='auto'>
              <Item name='gender' noStyle>
                <Select placeholder='请选择' className={style.full}>
                  <Option value='全部'>全部</Option>
                  <Option value='男'>男</Option>
                  <Option value='女'>女</Option>
                </Select>
              </Item>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col flex='80px' className={style.label}>职业：</Col>
            <Col flex='auto'>
              <Item name='occupation_out' noStyle>
                <Select placeholder='请选择' className={style.full}>
                  <Option value='全部'>全部</Option>
                  {occupation.map((li, i) => {
                    return <Option value={li.value} key={i}>{li.title}</Option>
                  })}
                </Select>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={style['input-line']}>
        <Col span={8}>
          <Row>
            <Col flex='80px' className={style.label}>专业：</Col>
            <Col flex='auto'>
              <Item name='professional' noStyle>
                <Select placeholder='请选择' className={style.full}>
                  <Option value='全部'>全部</Option>
                  {profession.map((li, i) => {
                    return <Option value={li.value} key={i}>{li.title}</Option>
                  })}
                </Select>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={style['input-line']}>
        <Col flex='80px' className={style.label}>所在区域：</Col>
        <Col flex='1'>
          <Item name='area' noStyle>
            <AreaSelector options={areas} />
          </Item>
        </Col>
      </Row>
      <div className={style['btn-line']}>
        <div className={style['selected-name']}>
          <span className={style['selected-name-label']}>当前选中：</span>
          <span>{selectedName === '' ? '未选择' : selectedName}</span>
        </div>
        <Space size='large'>
          <Button type='primary' className={style.btn} onClick={() => { onSearchBtnClick(); formRef.current?.submit() }}>查询</Button>
          <Button className={style.btn} onClick={() => formRef.current?.resetFields()}>重置</Button>
        </Space>
      </div>
    </Form>
  )
}

export default forwardRef(Main)
