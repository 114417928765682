import { $put } from '@js/request'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
// 更新当事人仲裁员选择结果
export async function updateArbitratorRequest (d: TribunalPartySchema): Promise<void> {
  const o: {[K: string]: any} = d
  delete o.tribunal_status
  delete o.tribunal_arbitrator
  delete o.tribunal_arbitrator1
  delete o.tribunal_arbitrator2
  delete o.tribunal_arbitratorid
  delete o.tribunal_arbitratorid1
  delete o.tribunal_arbitratorid2
  delete o.tribunal_assign_datetime
  const res = await $put('/api/hs/tribunal/partyTribunalUpdate', {
    tribunalid: d.tribunalid,
    tribunal_party: [o]
  })
  return res.data
}
