
import { CaseSchema } from '@components/Schema/CaseSchema'
import { PartySchema } from '@components/Schema/PartySchema'
import { AgentSchema } from '@src/components/Schema/AgentSchema'
// 初始化是否为本人
export function initIsSelf (caseDetail: CaseSchema, setIsSelf: (v: boolean) => void, accountid: string, id?: string): void {
  if (id === undefined) {
    return setIsSelf(false)
  }
  type Type = PartySchema | AgentSchema
  const d = caseDetail
  const l: Type[] = [...d.applicants, ...d.respondents, ...d.applicant_agents, ...d.respondents_agents]
  let o: Type | null = null
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id && l[i].accountid === accountid) {
      o = l[i]
      break
    }
  }
  if (o === null) {
    return setIsSelf(false)
  }
  setIsSelf(o.accountid === accountid)
}
