import { message } from 'antd'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { FileSchema } from '@src/components/Schema/FileSchema'
import { PartySchema } from '@src/components/Schema/PartySchema'
import { AgentSchema } from '@src/components/Schema/AgentSchema'

// 警告提示
async function warning (v: string): Promise<void> {
  await message.warning(v)
}

// 验证
export function validateCase (c: CaseSchema): boolean {
  if (c.applicants.length === 0) {
    warning('申请人不能为空').catch(e => console.error(e))
    return false
  } else if (!c.applicants.every(li => validateParty(li))) {
    warning('申请人信息不完善').catch(e => console.error(e))
    return false
  } else if (!c.applicant_agents.every(li => validateAgent(li))) {
    warning('代理人信息不完善').catch(e => console.error(e))
    return false
  } else if (c.respondents.length === 0) {
    warning('被申请人不能为空').catch(e => console.error(e))
    return false
  } else if (!c.respondents.every(li => validateParty(li))) {
    warning('被申请人信息不完善').catch(e => console.error(e))
    return false
  } else if (c.claim === undefined || c.claim === null || !(c.claim.items instanceof Array) || c.claim.items.length === 0) {
    warning('仲裁请求不能为空').catch(e => console.error(e))
    return false
  }
  return true
}

// 是否为空
function isEmpty (s: string | number | FileSchema[]): boolean {
  const t = typeof s
  if (t === 'string') {
    return s === ''
  } else if (t === 'number') {
    return s === 0
  } else if (s instanceof Array) {
    return s.length === 0
  }
  return s === undefined || s === null
}

// 验证当事人信息是否完善
export function validateParty (data: PartySchema): boolean {
  type Key = keyof PartySchema
  const keys: Key[] = ['tag', 'name', 'license_type', 'license', 'address_type', 'address']
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i]
    if (isEmpty(data[k])) {
      return false
    }
  }
  if (data.subject_type === '自然人') {
    // if (isEmpty(data.area) || isEmpty(data.fileid) || isEmpty(data.fileid1)) {
    if (isEmpty(data.area)) {
      return false
    }
  }
  return true
}

// 验证代理人信息是否完善
export function validateAgent (data: AgentSchema): boolean {
  type Key = keyof AgentSchema
  const keys: Key[] = ['name', 'license_type', 'license', 'structure', 'address']
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i]
    if (isEmpty(data[k])) {
      return false
    }
  }
  if (data.party === null || data.party.length === 0) {
    return false
  }
  return true
}
