import React, { ReactElement, useRef, useState, useEffect, RefObject } from 'react'
import { Modal, Form, FormInstance, Select, message, Space, Button } from 'antd'
import _ from 'lodash'
import { AgentPartySchema } from '@components/Schema/AgentSchema'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { PartySchema } from '@src/components/Schema/PartySchema'
import Dictionary, { DictionaryItem } from '@src/assets/js/dictionary'
import { awaitWrap } from '@js/tool'
import { FileSchema } from '@src/components/Schema/FileSchema'
import Upload, { uploadFile } from '@components/Upload/Index'

const { Item } = Form
const { Option } = Select

interface Props {
  visible: boolean
  data: AgentPartySchema | null
  index: number
  hide: () => void
  parties: PartySchema[]
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (v: AgentPartySchema, i: number) => void
  removeHandle: (i: number) => void
}

// 改变代理权限
function changeScopeType (v: string, formRef: RefObject<FormInstance>, setScopeType: (v: string) => void): void {
  const c = formRef.current
  setScopeType(v)
  if (v === '特殊代理' && c !== null) {
    c.setFieldsValue({ scope: undefined })
  }
}

// 上传事件
async function submitHandle (d: AgentPartySchema, origin: AgentPartySchema | null, i: number, onChange: (v: AgentPartySchema, i: number) => void): Promise<void> {
  let o = _.cloneDeep(d)
  if (origin !== null) {
    o = { ...origin, ...o }
  }
  if (o.upload !== undefined) {
    const [e, files] = await awaitWrap(uploadFile(o.upload))
    if (e !== null) {
      return undefined
    } else if (files === null) {
      return await message.error('文件未返回')
    }
    o.fileid = files.map(li => li.id).join()
    o.file = files
  }
  if (o.scope instanceof Array) {
    o.scope = o.scope.join()
  }

  onChange(o, i)
}

// 初始化form表单
function initForm (data: AgentPartySchema, formRef: RefObject<FormInstance>, setInitData: (v: any) => void): void {
  const c = formRef.current
  const d: { [t: string]: any } = { ...data }
  if (d.file !== undefined && d.file !== null) {
    d.upload = d.file.map((li: FileSchema) => {
      li.name = li.original_filename
      return li
    })
  }
  if (typeof d.scope === 'string') {
    d.scope = d.scope.split(',').filter((t: string) => t !== '')
  }
  if (c === null) {
    setInitData(d)
    return undefined
  }
  c.setFieldsValue(d)
}

function Main ({ visible, data, hide, parties, index, dictionary, onChange, removeHandle }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  const [scopeType, setScopeType] = useState('')
  const [specialType, setSpecialType] = useState<DictionaryItem[]>([])
  const [initData, setInitData] = useState<any>(null)
  useEffect(() => {
    if (data !== null && visible) {
      setScopeType(data.scope_type)
      initForm(data, formRef, setInitData)
    }
    if (data === null && visible) {
      setInitData({})
    }
  }, [data, formRef, visible])
  useEffect(() => {
    setSpecialType(dictionary.getList('特殊代理权限'))
  }, [dictionary])
  useEffect(() => {
    if (!visible) {
      setScopeType('')
    }
  }, [visible])
  return (
    <Modal
      title={data === null ? '添加代理对象' : '修改代理对象'}
      visible={visible}
      onCancel={() => hide()}
      maskClosable={false}
      footer={(
        <Space>
          {data !== null ? <Button type='ghost' danger onClick={() => removeHandle(index)}>删除</Button> : null}
          <Button onClick={hide}>取消</Button>
          <Button type='primary' onClick={() => formRef.current?.submit()}>确定</Button>
        </Space>
      )}
      destroyOnClose
    >
      <Form ref={formRef} labelCol={{ flex: '88px' }} initialValues={initData} onFinish={async d => await submitHandle(d, data, index, onChange)}>
        <Item name='type' noStyle><span /></Item>
        <Item name='partyid' label='代理对象' rules={[{ required: true, message: '代理对象不能为空' }]}>
          <Select placeholder='请选择代理对象'>
            {parties.map((li, i) => (
              <Option key={li.id} value={li.id as string}>{li.name}</Option>
            ))}
          </Select>
        </Item>
        <Item name='scope_type' label='代理类型' rules={[{ required: false, message: '代理类型不能为空' }]}>
          <Select placeholder='请选择代理对象' onChange={v => changeScopeType(v as string, formRef, setScopeType)} allowClear>
            <Option value='一般代理'>一般代理</Option>
            <Option value='特殊代理'>特殊代理</Option>
          </Select>
        </Item>
        {scopeType === '特殊代理'
          ? (
          <Item name='scope' label='代理权限' rules={[{ required: false, message: '代理权限不能为空' }]}>
            <Select placeholder='请选择代理权限' mode='multiple'>
              {specialType.map(li => <Option key={li.title} value={li.value}>{li.title}</Option>)}
            </Select>
          </Item>
            )
          : null}
        <Item name='upload' label='代理附件' rules={[{ required: false, message: '代理附件不能为空' }]}>
          <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
        </Item>
      </Form>
    </Modal>
  )
}

export default Main
