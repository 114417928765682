import { $get } from '@js/request'

interface AreaOption {
  本地: string[]
  外籍: string[]
  外地: string[]
}

// 获取地域选项
export async function getArea (): Promise<AreaOption> {
  const res = await $get('/api/xapc/arbitrator/area')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
