import { ReactElement } from 'react'
import { Result } from 'antd'

function Main (): ReactElement {
  return (
    <div>
       <Result
        status="warning"
        title="案件审核中"
        subTitle="请联系管理员"
      />
    </div>
  )
}

export default Main
