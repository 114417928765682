import React, { ReactElement } from 'react'
import Header from '../Header/Index'
import icon from '@imgs/evidence-icon.png'
import style from './Index.module.less'
import { EvidenceSchema } from '@src/components/Schema/EvidenceSchema'
import PicList from '../PicList/Index'

interface Props {
  data: EvidenceSchema[]
}

function Item ({ data, index }: { data: EvidenceSchema, index: number }): ReactElement {
  return (
    <div>
      <div className={style.item}>
        <div className={style.order}>
          <span>{index + 1}</span>
        </div>
        <div className={style.content}>
          <div>{data.name}</div>
          <div>{data.proof}</div>
        </div>
      </div>
      <PicList data={data.file} />
    </div>
  )
}

function Main ({ data }: Props): ReactElement {
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  return (
    <div className={style.container}>
      <Header icon={icon} title='证据材料' />
      {
        data.map((li, i) => {
          return <Item key={i} data={li} index={i} />
        })
      }
    </div>
  )
}

export default Main
