import { ReactElement } from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

function Main (): ReactElement {
  const h = useHistory()
  return (
    <div>
      <Result
        status="success"
        title="提交成功"
        subTitle="请等待审核"
        extra={[
          <Button type='primary' onClick={() => h.push('/create-case')}>继续立案</Button>
        ]}
      />
    </div>
  )
}

export default Main
