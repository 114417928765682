import React, { ReactElement } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FileSchema } from '@components/Schema/FileSchema'
import { awaitWrap } from '@js/tool'
import { requestFiles } from '@js/request'

interface Props {
  value?: FileType[]
  onChange?: (v: FileType[]) => void
  accept: string
}

interface UploadFile {
  filename: string
  id: string
  url: string
}

function getUploadProps (accept: string, fileList: FileType[], setFileList: (v: FileType[]) => void): Object {
  return {
    onRemove: (file: FileType) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file: FileType) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
    accept
  }
}

async function uploadsRequst (files: File[]): Promise<UploadFile[]> {
  const arr = await requestFiles(files)
  return arr
}

export async function uploadFile (fileList: FileType[]): Promise<FileSchema[]> {
  let uploaded: FileSchema[] = []
  const files: File[] = []
  fileList.forEach(li => {
    if (li instanceof File) {
      files.push(li)
    } else if (li.originFileObj instanceof File) {
      files.push(li.originFileObj)
    } else {
      uploaded.push(li)
    }
  })
  if (files.length > 0) {
    const hide = message.loading('上传中')
    const [e, d] = await awaitWrap(uploadsRequst(files))
    hide()
    if (e !== null) {
      return []
    }
    if (d === null) {
      message.error('数据没有返回').then(null, null)
    } else {
      uploaded = uploaded.concat(d.map((li, i) => {
        const file = files[i]
        return {
          ...li,
          original_filename: file.name,
          path: li.url
        }
      }))
    }
  }
  return uploaded
}

function Main ({ value = [], accept, onChange = () => {} }: Props): ReactElement {
  return (
    <Upload {...getUploadProps(accept, value, onChange)}>
      <Button>
        <UploadOutlined /> 上传文件
      </Button>
    </Upload>
  )
}

export type FileType = FileSchema | File

export default Main
