export interface State {
  zoneNum: string
  mobile: string
  mobileError: string
  validateCode: string
  validateCodeError: string
  agree: boolean
  username: string
  usernameError: string
  password: string
  passwordError: string
  login: boolean
}

export const initialState: State = {
  zoneNum: '86',
  mobile: '',
  mobileError: '',
  validateCode: '',
  validateCodeError: '',
  agree: true,
  username: '',
  usernameError: '',
  password: '',
  passwordError: '',
  login: false
}

export function reducer (state: State, action: { type: string, data: any }): State {
  return {
    ...state,
    [action.type]: action.data
  }
}
