import React, { ReactElement } from 'react'
import { Row, Col, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import style from './Index.module.less'

interface Props {
  onClick: () => void
}

function Main ({ onClick }: Props): ReactElement {
  return (
    <Row>
      <Col flex='40px' />
      <Col span={14} className={style['add-btn-container']}>
        <Button icon={<PlusOutlined />} type='dashed' block onClick={onClick}>添加请求项</Button>
      </Col>
    </Row>
  )
}

export default Main
