import React, { ReactElement, RefObject, useRef, useEffect } from 'react'
import { Form, FormInstance, Input, Space, Button } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@js/dictionary'
import { SubData } from '../../ajax'
import { ClaimItemSchema } from '@src/components/Schema/ClaimSchema'

const { Item } = Form
const { TextArea } = Input

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  statement?: string
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const o: FormData = { statement: caseDetail.claim.statement }
  c.setFieldsValue(o)
}

// 提交
async function submitHandle (d: FormData, caseDetail: CaseSchema, onchange: (d: SubData) => void): Promise<void> {
  const o: SubData = { claim: caseDetail.claim }
  o.claim.items.forEach((li: ClaimItemSchema) => {
    delete li.id
  })
  o.claim.statement = d.statement
  onchange(o)
}

function Main ({ history, caseDetail, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  useEffect(() => {
    initData(formRef, caseDetail)
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>事实与理由</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, caseDetail, onChange)}>
        <Item label='事实与理由' name='statement'>
          <TextArea className={style.long} autoSize={{ minRows: 5 }} placeholder='请输入事实与理由' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
