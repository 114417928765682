export const columns = [
  {
    title: '序号',
    name: 'no',
    dataIndex: 'no'
  },
  {
    title: '姓名',
    name: 'name',
    dataIndex: 'name'
  },
  {
    title: '职业分类',
    name: 'occupation',
    dataIndex: 'occupation'
  },
  {
    title: '擅长专业',
    name: 'cause_action',
    dataIndex: 'cause_action'
  },
  {
    title: '性别',
    name: 'gender',
    dataIndex: 'gender'
  },
  {
    title: '所在区域',
    name: 'area',
    dataIndex: 'area'
  }
]
