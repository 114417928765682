import React, { ReactElement, RefObject, useRef, useEffect } from 'react'
import { Form, FormInstance, Space, Button, message } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import _ from 'lodash'
import { CaseSchema } from '@components/Schema/CaseSchema'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import Upload, { FileType, uploadFile } from '@components/Upload/Index'
import { SubData } from '../../ajax'
import { awaitWrap } from '@js/tool'
import { ClaimItemSchema } from '@src/components/Schema/ClaimSchema'

const { Item } = Form

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  onChange: (d: SubData) => void
}

interface FormData {
  file?: FileType[]
}

// 初始化表单数据
function initData (formRef: RefObject<FormInstance>, caseDetail: CaseSchema): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  const o: FormData = { }
  if (caseDetail.claim.file instanceof Array) {
    o.file = caseDetail.claim.file.map(li => {
      li.name = li.original_filename
      return li
    })
  }
  c.setFieldsValue(o)
}

// 提交
async function submitHandle (d: FormData, caseDetail: CaseSchema, onchange: (d: SubData) => void): Promise<void> {
  const o: SubData = { }
  if (d.file !== undefined) {
    const [e, files] = await awaitWrap(uploadFile(d.file))
    if (e !== null) {
      return undefined
    } else if (files === null) {
      return await message.error('文件未返回')
    }
    o.claim = _.cloneDeep(caseDetail.claim)
    o.claim.items.forEach((li: ClaimItemSchema) => {
      delete li.id
    })
    delete o.claim.file
    o.claim.fileid = files.map(li => li.id).join()
  }
  onchange(o)
}

function Main ({ history, caseDetail, onChange }: Props): ReactElement {
  const formRef = useRef(null)
  useEffect(() => {
    initData(formRef, caseDetail)
  }, [caseDetail, formRef])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>仲裁申请书</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, caseDetail, onChange)}>
        <Item name='file' label='仲裁申请书'>
          <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default Main
