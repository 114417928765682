import React from 'react'
import classnames from 'classnames'
import { CloseCircleOutlined } from '@ant-design/icons'
import style from './ClearBtn.module.less'

interface Props {
  visible: boolean
  onClick: () => void
}

function Main ({ visible, onClick }: Props): React.ReactElement {
  const vname = classnames(style.container, { [style.active]: visible })
  return (
    <div className={vname}>
      <CloseCircleOutlined onClick={() => onClick()} />
    </div>
  )
}

export default Main
