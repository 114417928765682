import React, { ReactElement } from 'react'
import Header from '../Header/Index'
import icon from '@imgs/claim-icon.png'
import style from './Index.module.less'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { isEmptyString } from '@js/tool'
import PicList from '../PicList/Index'

interface Props {
  data: CaseSchema
}

function Main ({ data }: Props): ReactElement {
  if (isEmptyString(data.basis)) {
    return <span />
  }
  return (
    <div className={style.container}>
      <Header icon={icon} title='仲裁依据' />
      <div className={style.message}>
        <span>{data.basis}</span>
      </div>
      <PicList data={data.basis_file} />
    </div>
  )
}

export default Main
