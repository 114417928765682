import React, { ReactElement } from 'react'
import style from './Index.module.less'

interface Props {
  icon: string
  title: string
  subTitle?: string
}

function Main ({ icon, title, subTitle }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.icon}>
        <img src={icon} />
      </div>
      <span className={style['header-title']}>{title}</span>
      {typeof subTitle === 'string' && subTitle.length > 0 ? <span className={style['header-subtitle']}>{subTitle}</span> : <span />}
    </div>
  )
}

export default Main
