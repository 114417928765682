import { FileSchema } from '@src/components/Schema/FileSchema'
import React, { ReactElement } from 'react'
import style from './Index.module.less'

interface Props {
  data?: FileSchema[]
}

function Main ({ data }: Props): ReactElement {
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  return (
    <div className={style.container}>
      {
        data.map(li => {
          return (
            <div className={style.item} key={li.id}>
              <a href={li.url} target='_blank' className={style.link}>
                {li.original_filename}
              </a>
            </div>
          )
        })
      }
    </div>
  )
}

export default Main
